// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../../../../package.json';

export const environment = {
    production: false,
    apiURL: 'https://symdev.actassa.com/api',
    botURL: 'wss://symdev.actassa.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyBP1twgDbkfmTHIG9j-onmhD5UuCzqt7A8',
        authDomain: 'symmetry-hr.firebaseapp.com',
        projectId: 'symmetry-hr',
        storageBucket: 'symmetry-hr.appspot.com',
        messagingSenderId: '318677294711',
        appId: '1:318677294711:web:6170371c1d20dad5beb072',
    },
    socketIoConfig: {
        url: 'https://symdev.actassa.com',
        options: {
            autoConnect: true,
            path: '/messaging',
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.symmetry',
    appleAppId: '1543990033',
    appName: 'Symmetry HR',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
