import { AUTH_SERVICE_TOKEN, ENVIRONMENT_TOKEN, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { jobsPlacementsAfterDeserialize, jobsPlacementsBeforeSerialize } from '@actassa/jobs-placements';
import { AppSettingsDataService, APP_SETTINGS_TOKEN } from '@actassa/shared';
import { AuthInterceptor } from '@actassa/shared';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from 'environments/environment';
import { SocketIoModule } from 'ngx-socket-io';
import { AuthService } from 'services/auth.service';
import { StoreWrapperService } from 'services/store-wrapper.service';
import { RootState } from 'state/root-state/root.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessagingModule } from './modules/messaging/messaging.module';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { PushHandlerService } from 'services/push-handler.service';

Sentry.init({
    dsn: 'https://d28c3ac6adea443ab65827bde5d1ccc3@o802773.ingest.sentry.io/6629832',
    release: environment.appVersion,
    environment: environment.production ? 'prod' : 'dev',
    integrations: [
        new BrowserTracing({
            tracingOrigins: ['localhost', 'https://symmetry.actassa.com/mobile'],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    enabled: environment.production,
});

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true,
            mode: 'md',
        }),
        AppRoutingModule,
        NgxsModule.forRoot([RootState], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: environment.production,
                injectContainerState: false,
            },
        }),
        NgxsDispatchPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
            afterDeserialize: (obj: any, key: string) => {
                const store = jobsPlacementsAfterDeserialize(obj, key);

                return store;
            },
            beforeSerialize: (obj: any, key: string) => {
                const store = jobsPlacementsBeforeSerialize(obj, key);

                return store;
            },
        }),
        // NgxsAsyncStoragePluginModule.forRoot(StorageService),
        // IonicStorageModule.forRoot({
        //     name: 'Outperform',
        //     driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        // }),
        BrowserAnimationsModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideMessaging(() => {
            const providedMessaging = getMessaging(getApp());

            return providedMessaging;
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        // NgxsResetPluginModule.forRoot(),
        SocketIoModule.forRoot(environment.socketIoConfig),
        MessagingModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ENVIRONMENT_TOKEN, useValue: environment },
        { provide: STORE_WRAPPER_TOKEN, useClass: StoreWrapperService },
        { provide: AUTH_SERVICE_TOKEN, useClass: AuthService },
        { provide: APP_SETTINGS_TOKEN, useClass: AppSettingsDataService },
        // StatusBar,
        // SplashScreen,
        // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => (): void => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        Diagnostic,
        PushHandlerService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
